<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Warehouses/View</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <!-- for large screen -->
            <div class="d-none d-md-block">
              <div class="row">
                <div class="col-md-3 text-center  ">
                  <p @click="tabJump(1)" class="py-1 my-0 " :class="[tabStep === 1 ? 'active-form' :'inactive-form']">
                    Address Information</p>
                </div>
                <div class="col-md-3 text-center ">
                  <p @click="tabJump(2)" class="py-1 my-0" :class="[tabStep === 2 ? 'active-form' : 'inactive-form']">
                    Warehouse
                    Information</p>
                </div>
              </div>
            </div>
            <!-- for small screen  -->
            <div class="d-block d-md-none">
              <div class="row">
                <div v-if="tabStep === 1" class="col-md-10 text-center  ">
                  <p @click="tabJump(1)" class="py-1 my-0 active-form">Address Information</p>
                </div>
                <div v-else-if="tabStep === 2 " class="col-md-10 text-center ">
                  <p @click="tabJump(2)" class="py-1 my-0 active-form">Warehouse Information</p>
                </div>
              </div>
            </div>
            <!-- content section -->
            <div class="py-2">
              <div v-if="tabStep === 1">
                <div class="row ">
                  <div v-if="!addressId" class="col-12 col-md-12">
                    <div class="form-group">
                      <label>Address</label>
                      <vue-google-autocomplete :enable-geolocation="true" ref="address" :country="['au']" id="map"
                                               classname="form-control" placeholder="Please type full address."
                                               v-on:placechanged="googleMapApiResponseData"/>
                    </div>
                  </div>
                </div>
                <div v-if="hasAddressData">
                  <p v-if="!addressId" class="text-danger">Please modify the following address fields, if there anything
                    wrong.</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Street Address</label>
                        <input v-model="putOrPostAddressData.street" type="text" class="form-control"
                               placeholder="Enter street address"/>
                        <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 ">
                      <div class="form-group">
                        <label>Suburbs/ Territory</label>
                        <input v-model="putOrPostAddressData.suburb" type="text" class="form-control"
                               placeholder="Enter suburbs/ territory"/>
                        <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>State</label>
                        <VueMultiselect v-model="selectedState" class="" :options="state" :close-on-select="true"
                                        placeholder="Select state" label="name" track-by="value" :show-labels="false"/>
                        <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Post Code</label>
                        <input v-model="putOrPostAddressData.post_code" type="text" class="form-control"
                               placeholder="Enter code"/>
                        <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Country</label>
                        <input v-model="putOrPostAddressData.country" type="text" class="form-control" readonly/>
                        <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex justify-content-end">
                      <button type="button" @click="updateOrCreateSingleAddress"
                              class="btn btn-primary update-todo px-4">Update & Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="tabStep === 2">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Warehouse name</label>
                      <input v-model="putWarehouseData.name" type="text" class="form-control text-capitalize"
                             placeholder="Enter warehouse name">
                      <div class="text-danger" v-if="errors.warehouse.name">{{ errors.warehouse.name }}</div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input v-model="putWarehouseData.contact_number" type="text" class="form-control "
                             placeholder="ie. 0412345678">
                      <div class="text-danger"></div>
                    </div>

                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>GPS Location</label>
                      <input v-model="putWarehouseData.gps_location" type="text" class="form-control" placeholder="">
                      <div class="text-danger"></div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Role</label>
                      <VueMultiselect v-model="selectedStoreManager" class="" :options="storeManager"
                                      :close-on-select="true"
                                      placeholder="Select state" label="name" track-by="value" :show-labels="false"/>
                      <div class="text-danger"></div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Status</label>
                      <VueMultiselect v-model="selectedStatus" class="" :options="status" :close-on-select="true"
                                      placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
                      <div class="text-danger"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ">
                    <div class="d-block d-sm-flex justify-content-end">
                      <div class="pb-1 pb-sm-0">
                        <button type="button" @click="tabPrev" class="btn btn-primary update-todo px-4">Prev</button>
                      </div>
                      <button type="button" @click="updateSingleWarehouse"
                              class="ml-0 ml-sm-2 btn btn-primary update-todo px-4">Update Warehouse
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';

import {mapActions, mapGetters} from "vuex";

import VueGoogleAutocomplete from 'vue-google-autocomplete';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  components: {
    AppLayout,
    VueGoogleAutocomplete,
  },
  mixins: [ShowToastMessage, Loader],
  name: "EditWarehouse",
  data() {
    return {
      tabStep: 1,
      wareHouseId: '',
      addressId: '',
      selectedRole: {
        value: '',
        name: 'None'
      },
      getSettingsParams: {
        type: ['default'],
        key: ['state', 'customer_type'],
      },
      getWarehouseParams: {
        with_relation: ['address', 'managedBy'],
      },
      initialAddressData: {
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: '',
      },
      putOrPostAddressData: {
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      initialWarehouseData: {
        name: '',
        contact_number: '',
        gps_location: '',
        status: '',
      },
      putWarehouseData: {
        name: '',
        contact_number: '',
        gps_location: '',
        status: '',
        managed_by: '',
      },
      status: [
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],
      selectedStatus: {
        value: '',
        name: 'Any'
      },

      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStoreManager: {
        value: '',
        name: 'None',
      },
      getStoreManagersParam: {
        status: 1,
        with_relation: ['user'],
      },
      errors: {
        address: {
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        warehouse: {
          managed_by: '',
          name: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      warehouse: 'appWarehouses/warehouse',
      // getRole: 'appRoles/role'
    }),
    hasAddressData() {
      return !!(this.addressId || this.putOrPostAddressData.street || this.putOrPostAddressData.suburb || this.putOrPostAddressData.state || this.putOrPostAddressData.post_code);
    },
    state() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },
    storeManager() {
      let storeUsers = this.$store.getters['appStoreManagers/storeManagers'].map((storeUser) => {
        let id = storeUser.user.id;
        let name = storeUser.user.first_name + ' ' + storeUser.user.last_name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...storeUsers];
    },

    isAddressDataChange() {
      return !((this.initialAddressData.street === this.putOrPostAddressData.street) &&
          (this.initialAddressData.suburb === this.putOrPostAddressData.suburb) &&
          (this.initialAddressData.state === this.putOrPostAddressData.state) &&
          (this.initialAddressData.postCode === this.putOrPostAddressData.post_code) &&
          (this.initialAddressData.country === this.putOrPostAddressData.country));
    },

    isWarehouseDataChange() {
      return !((this.initialWarehouseData.name === this.putWarehouseData.name) &&
          (this.initialWarehouseData.contact_number === this.putWarehouseData.contact_number) &&
          (this.initialWarehouseData.gps_location === this.putWarehouseData.gps_location) &&
          (this.initialWarehouseData.status === this.putWarehouseData.status) &&
          (this.initialWarehouseData.managed_by === this.putWarehouseData.managed_by))
    },
  },
  watch: {
    selectedState() {
      this.putOrPostAddressData.state = this.selectedState.value;
    },

    selectedStatus() {
      this.putWarehouseData.status = this.selectedStatus.value;
    },
    selectedStoreManager() {
      this.putWarehouseData.managed_by = this.selectedStoreManager.value;
    },
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getWarehouse: 'appWarehouses/getWarehouse',
      putWarehouse: 'appWarehouses/putWarehouse',
      resetWarehouse: 'appWarehouses/resetWarehouse',
      putAddress: 'appAddresses/putAddress',
      getStoreManagers: 'appStoreManagers/getStoreManagers',
    }),
    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getSingleWarehouse(id) {
      const paramObj = {
        id: id,
        params: this.getWarehouseParams,
      };
      await this.getWarehouse(paramObj).then((response) => {
        if (response.status === 200) {
          //assigning Ids
          this.wareHouseId = this.warehouse.id;
          this.addressId = this.warehouse.address && this.warehouse.address.id ? this.warehouse.address.id : '';
          //address model
          if (this.warehouse.address && this.warehouse.address.state) {
            let addressStateIndex = this.state.findIndex(item => item.value === this.warehouse.address.state.toLowerCase());

            if (addressStateIndex !== -1) {
              let addressState = this.state[addressStateIndex];

              this.selectedState = {
                value: addressState.value,
                name: addressState.name,
              }
            }
          }
          //store manager model
          if (this.warehouse.managedBy && this.warehouse.managedBy.id) {
            let managerIndex = this.storeManager.findIndex(item => item.value === this.warehouse.managedBy.id);

            if (managerIndex !== -1) {
              let manager = this.storeManager[managerIndex];

              this.selectedStoreManager = {
                value: manager.value,
                name: manager.name,
              }
            }
          }

          //status
          if (this.warehouse && this.warehouse.status) {
            let statusIndex = this.status.findIndex(item => item.name === this.warehouse.status);

            if (statusIndex !== -1) {
              let status = this.status[statusIndex];

              this.selectedStatus.value = status.value;
              this.selectedStatus.name = status.name;
            }
          }

          this.initialAddressData = {
            street: this.warehouse.address && this.warehouse.address.street ? this.warehouse.address.street : '',
            suburb: this.warehouse.address && this.warehouse.address.suburb ? this.warehouse.address.suburb : '',
            state: this.selectedState.value,
            postCode: this.warehouse.address && this.warehouse.address.post_code ? this.warehouse.address.post_code : '',
            country: this.warehouse.address && this.warehouse.address.country ? this.warehouse.address.country : '',
          };
          this.putOrPostAddressData = {
            street: this.warehouse.address && this.warehouse.address.street ? this.warehouse.address.street : '',
            suburb: this.warehouse.address && this.warehouse.address.suburb ? this.warehouse.address.suburb : '',
            state: this.selectedState.value,
            post_code: this.warehouse.address && this.warehouse.address.post_code ? this.warehouse.address.post_code : '',
            country: this.warehouse.address && this.warehouse.address.country ? this.warehouse.address.country : '',
          };
          //warehouse model
          this.initialWarehouseData = {
            name: this.warehouse && this.warehouse.name ? this.warehouse.name : '',
            contact_number: this.warehouse && this.warehouse.contact_number ? this.warehouse.contact_number : '',
            gps_location: this.warehouse && this.warehouse.gps_location ? this.warehouse.gps_location : '',
            status: this.selectedStatus.value,
            managed_by: this.selectedStoreManager.value,
          };
          this.putWarehouseData = {
            name: this.warehouse && this.warehouse.name ? this.warehouse.name : '',
            contact_number: this.warehouse && this.warehouse.contact_number ? this.warehouse.contact_number : '',
            gps_location: this.warehouse && this.warehouse.gps_location ? this.warehouse.gps_location : '',
            status: this.selectedStatus.value,
            managed_by: this.selectedStoreManager.value,
          };
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false,
        loader: 'spinner',
        height: 80,
        width: 80,
        color: '#5a8dee',
        onCancel: this.onCancel,
      });

      let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
      let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
      let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
      let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
      let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
      let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';


      this.putOrPostAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.putOrPostAddressData.suburb = suburbs;
      this.putOrPostAddressData.state = state.toLowerCase();
      this.putOrPostAddressData.post_code = postCode;
      this.putOrPostAddressData.country = country;

      if (state) {
        let addressStateIndex = this.state.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.state[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }

      setTimeout(() => {
        loader.hide();
      }, 600);
    },

    async updateOrCreateSingleAddress() {
      if (!this.isAddressDataChange) {
        //console.log('not changed');
        await this.tabNext();
      }
      if (this.addressId && this.isAddressDataChange) {
        // console.log('change & have address id');
        const paramObj = {
          id: this.addressId,
          data: this.putOrPostAddressData,
        };

        await this.putAddress(paramObj).then((response) => {
          if (response.status === 200) {
            const toastObj = {message: 'Address info has updated.', type: 'success'};

            this.showToastMessage(toastObj)

            this.initialAddressData = {
              street: this.putOrPostAddressData.street,
              suburb: this.putOrPostAddressData.suburb,
              state: this.putOrPostAddressData.state,
              postCode: this.putOrPostAddressData.post_code,
              country: this.putOrPostAddressData.country,
            };

            this.tabNext();
          }

          this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
          this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
          this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
          this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
          this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }
        });
      }
    },

    async updateSingleWarehouse() {
      if (this.isWarehouseDataChange) {
        const wareHouseParamObj = {
          id: this.wareHouseId,
          data: this.putWarehouseData,
        };

        await this.putWarehouse(wareHouseParamObj).then(async (response) => {
          if (response.status === 200) {
            let toastObj = {message: 'Warehouse related info has Updated', type: 'success'};
            this.showToastMessage(toastObj);
            await this.$router.replace({
              name: 'appWarehouseList',
            });
          }
          this.errors.warehouse.name = response.errors && response.errors.name ? response.errors.name[0] : '';
          if (response.message) {
            this.showToastMessage(response);
          }
          if (this.errors.warehouse.name) {
            await this.tabJump(2);
          }
        });
      }

    }
  },
  async mounted() {
    await this.loader(true);
    const routeParams = this.$route.params;
    await this.getSettingList(this.getSettingsParams);
    await this.getStoreManagers(this.getStoreManagersParam)
    await this.getSingleWarehouse(routeParams.id);
    await this.loader(false);

  },
  beforeUnmount() {
    this.resetWarehouse();
  },
}
</script>

<style scoped>
.active-form {
  background-color: #5A8DEE;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 995px), screen and (max-height: 700px) {
  .active-form {
    background-color: #5A8DEE;
    color: white;
    font-weight: bold;
  }
}
</style>
